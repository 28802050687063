
import request from "@/utils/request";
import { ElMessageBox, ElTable } from "element-plus";

import {
  defineComponent,
  onMounted,
  PropType,
  toRefs,
  reactive,
  SetupContext,
  ref,
  nextTick,
  watch,
} from "vue";
import {Delete, Edit} from "@element-plus/icons-vue";



export default defineComponent({
  props: {
    //分页设置
    paginationLayout: {
      type: String,
    },
    height: {
      type: Number,
    },
    //列表请求url
    listUrl: {
      type: String,
    },
    //请求的额外参数
    otherPara: {
      type: Object,
    },
    //获取数据列表key
    listKey: {
      type: String,
    },
    //数据列表 显示数据key
    dataJson: {
      type: String,
    },
    initData: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      },
    },
    //对应列的prop
    props: {
      type: Array as PropType<string[]>,

      default: () => {
        return [];
      },
    },
    //对应列的title
    columnTitles: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      },
    },
    //自定义列展示
    //自定义列展示{prop:name}
    customColumnSlots: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customColumnWidth: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customColumnNotAlign: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //排序列
    sortColumns:{
      type: Array as PropType<number[]>,
      default: () => {
        return [];
      },
    },
    //table最大高度
    maxHeight: {
      type: String,
      default: () => {
        return "450";
      },
    },
    //内容显示处理
    formater: {
      type: Function,
    },
    //操作列宽度
    operationWidth: {
      type: String,
      default: () => {
        return "150";
      },
    },
    //显示操作列
    showOperation: {
      type: Boolean,
    },
    //显示边框
    border: {
      type: Boolean,
    },
    //显示勾选框
    showCheckbox: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    //显示Index
    showIndex: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    //删除操作
    deleteAction: {
      type: Function,
    },
    //编辑操作
    editAction: {
      type: Function,
    },


    //高亮显示当前行
    highLightRow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    rowClick: {
      type: Function,
    },
    showPagination:{
      type: Boolean,
      default: () => {
        return true;
      },
    },
    needStyle:{
      type: Boolean,
      default: () => {
        return false;
      },
    },
    hideSpace:{
      type: Boolean,
      default: () => {
        return false;
      },
    },
    rowKey:{
      type: String,
      default: () => {
        return "id";
      }
    },
    scrollbarAlwaysOn:{
      type: Boolean,
      default:false,
    },
    headerRowStyle:{
      type: Function,

    },
    rowStyle:{
      type: Function,

    },
  },
  // emits:['pageAndSizeChange','backData','selectionChange','currentRowChange'],

  setup(prop: any, ctx: SetupContext) {
    // let a: string[] = prop.props as string[];
    const table = ref<null | typeof ElTable>();

    const dataList: Record<string, any>[] = [];
    const state = reactive({
      // keys: a,
      page: 1,
      size: 10,
      total: 0,
      loading: true,
      dataList,
    });

    onMounted(() => {
      getList();

    });
    watch(()=>prop.initData?.length,()=>{

      if(prop.initData){
        state.dataList = prop.initData;
      }
    });

    return {
      ...toRefs(state),
      pageAndSizeChange,
      getList,

      handleSelectionChange,
      handleCurrentRowChange,
      table,
      setCurrentRow,

      alertDeleteAction,
    };
    // function headerRowStyle(row: any) {
    //   if(prop.needStyle){
    //     return {'background-color': '#27579c','color':'white','border-color':'#1579d5'};
    //
    //   }else{
    //     return {};
    //   }
    // }
    // function rowStyle(row: any) {
    //   if(prop.needStyle){
    //     if(row.rowIndex%2==0){
    //       return {'background-color': '#05325b','color':'white','border-color':'#1579d5'};
    //     }else{
    //       return {'background-color': '#02122a','color':'white','border-color':'#1579d5'};
    //     }
    //
    //   }else{
    //     return {};
    //   }
    // }

    function pageAndSizeChange(val: Record<string, any>) {
      ctx.emit("pageAndSizeChange");

      getList();
    }

    function getList() {
      let para: Record<string, any> = prop.otherPara ?? {};

      para.pageSize = state.size;
      para.pageNum = state.page;


      if(!prop.listUrl){
        state.loading = false;
        if(prop.initData){
          state.dataList = prop.initData;
        }

        return;
      }

      request({
        url: prop.listUrl,
        method: "GET",
        params: para,
      }).then((res) => {
        state.loading = false;
        const d = (res as Record<string, any>).content;
        state.total = d.total ?? d.totalCount ?? d.TotalCount;

        if (prop.dataJson) {
          const a: Record<string, any>[] = d[prop.listKey] ?? [];

          state.dataList = a.map((e) => e[prop.dataJson]);
        } else {
          state.dataList = d[prop.listKey] ?? [];
        }

        ctx.emit("backData", d);
      });
    }
    function handleSelectionChange(arr: Array<any>) {
      ctx.emit("selectionChange", arr);
    }
    function handleCurrentRowChange(row: any) {
      ctx.emit("currentRowChange", row);
    }

    function setCurrentRow(rowKey: string, rowValue: string) {
      const a = state.dataList.find((e) => {
        return e[rowKey] == rowValue;
      });
      console.log(a);
      table.value?.setCurrentRow(a);
    }

    function alertDeleteAction(row:any, index?:number){
      ElMessageBox.confirm("是否确定删除？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        prop.deleteAction(row,index);
      });

    }
  },
});
